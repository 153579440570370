import dayjs from 'dayjs';

export function logErrors(error, isAuthError = false) {
	const formattedErrorString = Object.keys(error)
		.map((key) => `${key}: ${JSON.stringify(error[key])}`)
		.join('\n\n');
	let url = `${process.env.REACT_APP_GOOGLE_BOT_NOTIFICATION_URL}?key=${process.env.REACT_APP_GOOGLE_BOT_NOTIFICATION_KEY}&token=${process.env.REACT_APP_GOOGLE_BOT_NOTIFICATION_TOKEN}`;
	if (isAuthError) {
		url = `${process.env.REACT_APP_GOOGLE_BOT_AUTH_URL}?key=${process.env.REACT_APP_GOOGLE_BOT_AUTH_KEY}&token=${process.env.REACT_APP_GOOGLE_BOT_AUTH_TOKEN}`;
	}
	const data = {
		text: formattedErrorString,
	};
	// eslint-disable-next-line no-console
	console.log(error);

	if (process.env.REACT_APP_BOT_NOTIFICATION_ENABLED === '1')
		fetch(url, {
			method: 'POST',
			body: JSON.stringify(data),
		})
			.then((response) => {
				if (!response.ok) {
					// eslint-disable-next-line no-console
					console.error('Bot Notification Request failed');
				}
				return response.json();
			})
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.error('Error:', error);
			});
}

export function getInitials(name) {
	const names = name?.split(' ');
	let firstName, lastName, shortForm;
	if (names?.length > 1) {
		firstName = names[0];
		lastName = names[names?.length - 1];
		shortForm = `${firstName?.charAt(0)?.toUpperCase()}${lastName
			.charAt(0)
			.toUpperCase()}`;
	} else {
		firstName = names?.[0];
		shortForm = `${firstName?.charAt(0)?.toUpperCase()}`;
	}
	return shortForm;
}

function convertDatesAndTimes(obj) {
	const {
		onward_date,
		return_date,
		onward_start_time,
		onward_end_time,
		return_start_time,
		return_end_time,
	} = obj;

	// Convert onward date to ISO format
	const onwardDate = dayjs(onward_date).format('YYYY-MM-DD');

	// Convert return date to ISO format
	const returnDate = dayjs(return_date).format('YYYY-MM-DD');

	// Create the desired output format for onward and return dates
	const onwardStartDateAndTime = `${onwardDate} ${onward_start_time.padStart(
		2,
		'0'
	)}:00`;
	const onwardEndDateAndTime = `${onwardDate} ${onward_end_time.padStart(
		2,
		'0'
	)}:00`;
	const returnStartDateAndTime = `${returnDate} ${return_start_time.padStart(
		2,
		'0'
	)}:00`;
	const returnEndDateAndTime = `${returnDate} ${return_end_time.padStart(
		2,
		'0'
	)}:00`;

	return {
		onwardDate,
		returnDate,
		onwardStartDateAndTime,
		onwardEndDateAndTime,
		returnStartDateAndTime,
		returnEndDateAndTime,
	};
}

export const formatDateAndTime = (
	timeFormat,
	legInfo,
	index,
	return_flight
) => {
	let travelStartTime, travelEndTime, travelDate;
	const {
		onwardDate,
		returnDate,
		onwardStartDateAndTime,
		onwardEndDateAndTime,
		returnStartDateAndTime,
		returnEndDateAndTime,
	} = convertDatesAndTimes(legInfo);

	if (legInfo.combine_layout) {
		// for showing same card for onward and return
		if (!return_flight) {
			travelStartTime = dayjs(onwardStartDateAndTime);
			travelEndTime = dayjs(onwardEndDateAndTime);
			travelDate = dayjs(onwardDate);
		} else {
			travelStartTime = dayjs(returnStartDateAndTime);
			travelEndTime = dayjs(returnEndDateAndTime);
			travelDate = dayjs(returnDate);
		}
	} else {
		travelStartTime = dayjs(onwardStartDateAndTime);
		travelEndTime = dayjs(onwardEndDateAndTime);
		travelDate = dayjs(onwardDate);
	}

	// Format the date portion of the flight info
	const formattedDate =
		travelDate?.format('D MMM').charAt(0).toUpperCase() +
		travelDate?.format('D MMM').slice(1);

	let formattedTravelStartTime = '';
	let formattedTravelEndTime = '';

	if (Number(timeFormat) === 12) {
		formattedTravelStartTime = travelStartTime.format('h A');
		formattedTravelEndTime = travelEndTime.format('h A');
	} else {
		formattedTravelStartTime = travelStartTime.format('HH:mm');
		formattedTravelEndTime = travelEndTime.format('HH:mm');
	}
	let formattedFlightInfo = '';
	if (formattedTravelStartTime === formattedTravelEndTime) {
		// formattedFlightInfo = `${formattedDate} ∙ ${formattedTravelStartTime}`; //! commenting out this logic. Don't know this was done on purpose or fallback logic
		formattedFlightInfo = `${formattedDate}`;
	} else if (
		formattedTravelStartTime.includes('AM') &&
		formattedTravelEndTime.includes('AM')
	) {
		formattedFlightInfo = `${formattedDate} ∙ ${formattedTravelStartTime.replace(
			' AM',
			''
		)} - ${formattedTravelEndTime.replace(' AM', '')} AM`;
	} else if (
		formattedTravelStartTime.includes('PM') &&
		formattedTravelEndTime.includes('PM')
	) {
		formattedFlightInfo = `${formattedDate} ∙ ${formattedTravelStartTime.replace(
			' PM',
			''
		)} - ${formattedTravelEndTime.replace(' PM', '')} PM`;
	} else {
		formattedFlightInfo = `${formattedDate} ∙ ${formattedTravelStartTime} - ${formattedTravelEndTime}`;
	}
	return <>{formattedFlightInfo}</>;
};

export const throttle = (callback, delay) => {
	let timerId;
	let shouldCall = true;

	return (...args) => {
		if (shouldCall) {
			callback(...args);
			shouldCall = false;
			timerId = setTimeout(() => {
				shouldCall = true;
			}, delay);
		}
	};
};

export function toSentenceCase(name) {
	const words = name.split(' ');
	const sentenceCaseName = words.map((word) => {
		if (word?.length > 0) {
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		}
		return word;
	});
	return sentenceCaseName.join(' ');
}

export const getObjectValueSum = (obj) =>
	Object.values(obj).reduce(
		(acc, val) => acc + (typeof val === 'number' ? val : 0),
		0
	);

export const convertHoursTo24HourFormat = (hourString, period) => {
	let hours = parseInt(hourString);

	// Convert 12AM to 00:00 and 12PM to 12:00
	if (hours === 12) {
		hours = period === 'AM' ? 0 : 12;
	} else {
		hours += period === 'PM' ? 12 : 0;
	}

	// Format the hours and return the result
	return hours;
};
