const LocalStorageService = (function () {
	let __service;

	function __getService() {
		if (!__service) {
			__service = this;
			return __service;
		}
	}
	/**---set access token in localStorage */
	function __setAccessToken(token) {
		window.localStorage.removeItem('il_app_access_token');
		localStorage.setItem('il_app_access_token', token);
	}

	/**---get access token from localStorage */
	function __getAccessToken() {
		if (localStorage.getItem('il_app_access_token')) {
			return localStorage.getItem('il_app_access_token');
		} else {
			return false;
		}
	}
	/**---set refresh token in localStorage */
	function __setRefreshToken(token) {
		window.localStorage.removeItem('il_app_refresh_token');
		localStorage.setItem('il_app_refresh_token', token);
	}

	/**---get refresh token from localStorage */
	function __getRefreshToken() {
		if (localStorage.getItem('il_app_refresh_token')) {
			return localStorage.getItem('il_app_refresh_token');
		} else {
			return false;
		}
	}
	function __setRole(role) {
		window.localStorage.removeItem('il_app_role');
		localStorage.setItem('il_app_role', role);
	}
	function __getRole() {
		if (localStorage.getItem('il_app_role')) {
			return localStorage.getItem('il_app_role');
		} else {
			return false;
		}
	}
	function __setClientID(id) {
		window.localStorage.removeItem('il_app_client_id');
		localStorage.setItem('il_app_client_id', id);
	}
	function __getClientID() {
		if (localStorage.getItem('il_app_client_id')) {
			return localStorage.getItem('il_app_client_id');
		} else {
			return false;
		}
	}
	function __setLocale(role) {
		window.localStorage.removeItem('il_app_locale');
		localStorage.setItem('il_app_locale', role);
	}
	function __getLocale() {
		if (localStorage.getItem('il_app_locale')) {
			return localStorage.getItem('il_app_locale');
		} else {
			return false;
		}
	}
	function __setExperience(experience) {
		window.localStorage.removeItem('il_app_experience');
		localStorage.setItem('il_app_experience', experience);
	}
	function __getExperience() {
		if (localStorage.getItem('il_app_experience')) {
			return localStorage.getItem('il_app_experience');
		} else {
			return false;
		}
	}
	// Removes all token
	function __removeAllToken() {
		localStorage.clear();
	}

	return {
		getService: __getService,
		removeAllToken: __removeAllToken,
		setAccessToken: __setAccessToken,
		getAccessToken: __getAccessToken,
		setRefreshToken: __setRefreshToken,
		getRefreshToken: __getRefreshToken,
		setRole: __setRole,
		getRole: __getRole,
		setClientID: __setClientID,
		getClientID: __getClientID,
		setLocale: __setLocale,
		getLocale: __getLocale,
		setExperience: __setExperience,
		getExperience: __getExperience,
	};
})();

export default LocalStorageService;
