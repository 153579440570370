import * as React from 'react';

export const ThreeDots = React.memo(
	({ size = 16, color = '#6B7280', className, ...rest }) => (
		<svg
			width={size}
			height="4"
			viewBox="0 0 14 4"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			{
				...rest
			}
		>
			<path d="M3.25 2C3.25 2.69036 2.69036 3.25 2 3.25C1.30964 3.25 0.75 2.69036 0.75 2C0.75 1.30964 1.30964 0.75 2 0.75C2.69036 0.75 3.25 1.30964 3.25 2Z" fill={color} />
			<path d="M8.25 2C8.25 2.69036 7.69036 3.25 7 3.25C6.30964 3.25 5.75 2.69036 5.75 2C5.75 1.30964 6.30964 0.75 7 0.75C7.69036 0.75 8.25 1.30964 8.25 2Z" fill={color} />
			<path d="M12 3.25C12.6904 3.25 13.25 2.69036 13.25 2C13.25 1.30964 12.6904 0.75 12 0.75C11.3096 0.75 10.75 1.30964 10.75 2C10.75 2.69036 11.3096 3.25 12 3.25Z" fill={color} />
		</svg>

	)
);

ThreeDots.displayName = 'ThreeDots';



