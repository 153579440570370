import * as React from 'react';
import { Select as AntSelect } from 'antd';
import clsx from 'clsx';
import { ChevronDown } from 'icons';
import PropTypes from 'prop-types';

import classes from './Select.module.scss';

const SelectComp = React.forwardRef((props, ref) => {
	const {
		className = '',
		value = null,
		placeholder = '',
		onChange = () => console.log('hello'),
		onClick = () => {},
		options = [],
		style = {},
		suffixIcon = <ChevronDown />,
		size = 'normal',
		...rest
	} = props;
	return (
		<>
			<AntSelect
				ref={ref}
				className={clsx(classes.select, classes[size], className)}
				value={value}
				onClick={onClick}
				placeholder={placeholder}
				style={style}
				defaultActiveFirstOption={false}
				filterOption={false}
				onChange={onChange}
				notFoundContent={''}
				placement="bottomRight"
				getPopupContainer={classes.popupContainer}
				options={options ? [...options] : null}
				popupClassName={classes.dropdown}
				suffixIcon={suffixIcon}
				popupMatchSelectWidth={false}
				{...rest}
			/>
		</>
	);
});

SelectComp.propTypes = {
	placeholder: PropTypes.string,
	className: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.any,
	options: PropTypes.array,
	style: PropTypes.object,
	onClick: PropTypes.func,
	suffixIcon: PropTypes.element,
};

export const Select = React.memo(SelectComp);
