import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	list: null,
	pagination: null,
	sort: null,
	search: '',
	loadingState: null,
	errorState: null,
	initCompleted: false,
};
export const LandingData = createSlice({
	name: 'LandingDataSlice',
	initialState,
	reducers: {
		setInitialLandingData: (state, action) => {
			const {
				list = {},
				pagination = {},
				sort = {},
				loadingState = {},
				errorState= {},
			} = action.payload;
			state.list = list;
			state.pagination = pagination;
			state.sort = sort;
			state.loadingState = loadingState;
			state.errorState = loadingState;
			state.initCompleted = true;
		},
		setLandingList: (state, action) => {
			state.list = action.payload;
		},
		setLandingPagination: (state, action) => {
			state.pagination = action.payload;
		},
		setLandingSort: (state, action) => {
			state.sort = action.payload;
		},
		setLandingSearch: (state, action) => {
			state.search = action.payload;
		},
		setLandingLoadingTabs: (state, action) => {
			state.loadingState = action.payload;
		},
		setLandingErrorTabs: (state, action) => {
			state.errorState = action.payload;
		},
	},
});

export const {
	setInitialLandingData,
	setLandingList,
	setLandingPagination,
	setLandingSort,
	setLandingSearch,
	setLandingLoadingTabs,
	setLandingErrorTabs,
} = LandingData.actions;

export default LandingData.reducer;
