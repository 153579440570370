import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { SystemError } from 'icons';
import LocalStorageService from 'services/LocalStorage';

import { Button } from 'components/atoms';

// import { Button } from 'components/atoms';

const ApprovalsErrors = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('approvals');
	const goBack = () => {
		navigate(-1);
	};

	return (
		<div
			className={clsx(
				'tw-flex tw-items-center tw-justify-center tw-flex-col tw-h-[90vh] tw-bg-[#F8F7F6]'
			)}
		>
			<SystemError color="#CF2231" />
			<p className="tw-text-color-text-subtle tw-my-4 tw-typography-title2">
				{t('error.heading')}
			</p>
			<div
				className={clsx(
					'tw-typography-body2 tw-text-color-text-subtle error_page tw-text-center'
				)}
			>
				<p className=" tw-typography-body2 tw-mb-1 tw-m-0">
					{t('error.desc_1')}
				</p>
				<p className=" tw-typography-body2Bold tw-m-0">
					{t('error.desc_2')}
				</p>
			</div>
			<div className="tw-typography-body2 tw-text-color-text-subtle tw-text-left tw-px-5 tw-py-4 tw-my-4 tw-rounded-md tw-bg-color-surface-default md:tw-w-[350px]">
				<p className="tw-mt-0 tw-mb-1">
					{'1. '}
					{t('error.point_1')}
				</p>
				<p className="tw-mt-0 tw-mb-1">
					{'2. '}
					{t('error.point_2')}
				</p>
				<p className="tw-mt-0 tw-mb-1">
					{'3. '}
					{t('error.point_3')}
				</p>
				<p className="tw-mt-0 tw-mb-0">
					{'4. '}
					{t('error.point_4_1')}
					<a
						href="mailto:support@itilite.com"
						className="tw-underline tw-text-color-text-subtle"
					>
						{'support@itilite.com'}
					</a>
					{t('error.point_4_2')}
				</p>
			</div>
			<div className="tw-flex tw-justify-between tw-mt-2">
				<Button
					className="tw-relative tw-typography-label"
					onClick={() => window.location.reload()}
				>
					{t('error.button_1')}
				</Button>
				{LocalStorageService.getAccessToken() && (
					<Button
						className="tw-ml-2 tw-relative tw-typography-label tw-text-color-text-primary"
						onClick={() => goBack()}
						type="default"
					>
						{t('error.button_2')}
					</Button>
				)}
			</div>
		</div>
	);
};

export { ApprovalsErrors };
