import * as React from 'react';

export const People = React.memo(
	({ size = 16, color = '#1B7E49', className, type = "single", ...rest }) => (
		type !== "single" ? <svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			{...rest}
		>
			<path
				d="M6.00195 4.00002C6.00195 2.89655 6.89649 2.00201 7.99995 2.00201C9.10342 2.00201 9.99796 2.89655 9.99796 4.00002C9.99796 5.10348 9.10342 5.99802 7.99995 5.99802C6.89649 5.99802 6.00195 5.10348 6.00195 4.00002ZM7.99995 3.00201C7.44877 3.00201 7.00195 3.44883 7.00195 4.00002C7.00195 4.5512 7.44877 4.99802 7.99995 4.99802C8.55114 4.99802 8.99796 4.5512 8.99796 4.00002C8.99796 3.44883 8.55114 3.00201 7.99995 3.00201ZM11 4.5C11 3.67157 11.6716 3 12.5 3C13.3284 3 14 3.67157 14 4.5C14 5.32843 13.3284 6 12.5 6C11.6716 6 11 5.32843 11 4.5ZM12.5 4C12.2239 4 12 4.22386 12 4.5C12 4.77614 12.2239 5 12.5 5C12.7761 5 13 4.77614 13 4.5C13 4.22386 12.7761 4 12.5 4ZM3.5 3C2.67157 3 2 3.67157 2 4.5C2 5.32843 2.67157 6 3.5 6C4.32843 6 5 5.32843 5 4.5C5 3.67157 4.32843 3 3.5 3ZM3 4.5C3 4.22386 3.22386 4 3.5 4C3.77614 4 4 4.22386 4 4.5C4 4.77614 3.77614 5 3.5 5C3.22386 5 3 4.77614 3 4.5ZM4.26756 6.99969C4.09739 7.29387 4 7.63541 4 7.99969L2 7.99969V10.5C2 11.3285 2.67157 12 3.5 12C3.71194 12 3.91361 11.9561 4.09639 11.8768C4.1705 12.2082 4.28572 12.524 4.43643 12.8187C4.14721 12.9356 3.83112 13 3.5 13C2.11929 13 1 11.8807 1 10.5V7.99969C1 7.44741 1.44772 6.99969 2 6.99969H4.26756ZM11.5636 12.8187C11.8528 12.9356 12.1689 13 12.5 13C13.8807 13 15 11.8807 15 10.5V7.99969C15 7.44741 14.5523 6.99969 14 6.99969H11.7324C11.9026 7.29387 12 7.63541 12 7.9997L14 7.99969V10.5C14 11.3285 13.3284 12 12.5 12C12.2881 12 12.0864 11.9561 11.9036 11.8768C11.8295 12.2082 11.7143 12.524 11.5636 12.8187ZM6 6.99969C5.44772 6.99969 5 7.44741 5 7.99969V11C5 12.6569 6.34315 14 8 14C9.65685 14 11 12.6569 11 11V7.99969C11 7.44741 10.5523 6.99969 10 6.99969H6ZM6 7.99969L10 7.99969V11C10 12.1046 9.10457 13 8 13C6.89543 13 6 12.1046 6 11V7.99969Z"
				fill={color}
			/>
		</svg> : <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 4C5.5 2.34315 6.84315 1 8.5 1C10.1569 1 11.5 2.34315 11.5 4C11.5 5.65685 10.1569 7 8.5 7C6.84315 7 5.5 5.65685 5.5 4ZM8.5 2.5C7.67157 2.5 7 3.17157 7 4C7 4.82843 7.67157 5.5 8.5 5.5C9.32843 5.5 10 4.82843 10 4C10 3.17157 9.32843 2.5 8.5 2.5ZM2.68433 10.3709C3.69 9.1069 5.46681 8 8.5 8C11.5332 8 13.31 9.1069 14.3157 10.3709C15.2926 11.5988 15.4664 12.8797 15.4934 13.1434C15.4993 13.2014 15.5 13.249 15.5 13.2833C15.5 14.2314 14.7314 15 13.7833 15H3.21668C2.26858 15 1.5 14.2314 1.5 13.2833C1.5 13.249 1.5007 13.2014 1.50664 13.1434C1.53364 12.8797 1.70737 11.5988 2.68433 10.3709ZM3.00001 13.2853C3.00109 13.4041 3.09768 13.5 3.21668 13.5H13.7833C13.9023 13.5 13.9989 13.4041 14 13.2853C13.9817 13.1214 13.8474 12.1916 13.1419 11.3048C12.4474 10.432 11.1207 9.5 8.5 9.5C5.87935 9.5 4.5526 10.432 3.85813 11.3048C3.15257 12.1916 3.01833 13.1214 3.00001 13.2853Z" fill={color} />
		</svg>

	)
);

People.displayName = 'People';
