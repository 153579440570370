import { createSlice } from '@reduxjs/toolkit';
import LocalStorageService from 'services/LocalStorage';
import { GLOBAL_EXPERIENCE } from 'utils/constants/globalExperience';

export const experienceSwitch = createSlice({
	name: 'experienceSwitch',
	initialState: {
		experience:
			LocalStorageService.getExperience() || GLOBAL_EXPERIENCE.BUSINESS,
		switching: false,
	},
	reducers: {
		setGlobalExperience: (state, action) => {
			state.experience = action.payload;
			LocalStorageService.setExperience(action.payload); // Persist to local storage
		},
		resetGlobalExperience: (state) => {
			state.experience = GLOBAL_EXPERIENCE.BUSINESS;
			LocalStorageService.setExperience(GLOBAL_EXPERIENCE.BUSINESS); // Persist to local storage
		},
		startSwitching: (state, action) => {
			state.switching = action.payload; // Set switching state to true
		},
		
	},
});

export const { setGlobalExperience, resetGlobalExperience, startSwitching } =
	experienceSwitch.actions;

export default experienceSwitch.reducer;
