import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Navigate,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import { paramsRedirects } from 'auth/paramsRedirects';
import axios from 'axios';
import {
	setAuthSuccess,
	setClientInfo,
	setLoaderDetails,
	setRedirectionPostLogin,
} from 'redux/features/common/slice/authService.slice';
import LocalStorageService from 'services/LocalStorage';
import { logErrors } from 'utils';
import { GLOBAL_EXPERIENCE } from 'utils/constants/globalExperience';
import { apiUrls } from 'utils/urls/apiUrls';
import routeURLs from 'utils/urls/routeUrls';

function AuthRoute(props) {
	// localStorage.clear(); // !clearing old sessions
	const { token, role, clientID, approute } = useParams(); //! get the params from the Auth url
	const [searchParams] = useSearchParams(); //! get the search param for dditional info
	const type = searchParams.get('type');

	const navigate = useNavigate();
	const redirectRef = useRef(false);
	const dispatch = useDispatch();

	const { authRefreshSuccess } = useSelector((state) => state.authService);

	const validateToken = useCallback(async () => {
		const data = {
			refresh: token,
		};
		axios
			.post(apiUrls.AUTH.FETCH_REFRESH_TOKEN, data)
			.then((res) => {
				if (res.status === 200) {
					const _token = res.data.token;
					redirectRef.current = paramsRedirects[approute]({
						searchParams,
						type,
					});
					// Local Storage tokens adding
					LocalStorageService.setAccessToken(_token.access);
					LocalStorageService.setAccessToken(_token.access);
					LocalStorageService.setRefreshToken(_token.refresh);
					LocalStorageService.setRole(role);
					LocalStorageService.setClientID(clientID);
					if (
						LocalStorageService.getExperience() !==
							GLOBAL_EXPERIENCE.BUSINESS ||
						LocalStorageService.getExperience() !==
							GLOBAL_EXPERIENCE.PERSONAL
					) {
						LocalStorageService.setExperience(
							GLOBAL_EXPERIENCE.BUSINESS
						);
					}
					localStorage.setItem('app', approute);
					localStorage.setItem('token', JSON.stringify(_token));
					props.setAppLoading(false);
					dispatch(setClientInfo(res.data));
					dispatch(setAuthSuccess(true));
					dispatch(setRedirectionPostLogin(redirectRef.current.url));
					dispatch(
						setLoaderDetails({
							loading: redirectRef.current.showLoader,
							type: redirectRef.current.showLoader
								? redirectRef.current.loaderType
								: 1,
						})
					);
				}
			})
			.catch((error) => {
				logErrors(
					{
						type: 'Redirection from PHP failed',
						url: window.location.href,
						...error.response,
					},
					true
				);
				if (error.response?.status === 401) {
					LocalStorageService.removeAllToken();
					window.location.href = process.env.REACT_APP_LOGIN_URL;
				}
			});
	}, [approute, clientID, dispatch, role, searchParams, token, type]);

	useEffect(() => {
		if (token && role && approute && clientID) {
			validateToken();
		}
	}, [approute, clientID, role, token, validateToken]);

	useEffect(() => {
		if (authRefreshSuccess)
			navigate(redirectRef.current.url, { replace: true });
	}, [authRefreshSuccess, navigate]);

	if (token && role && approute && clientID) {
		return null;
	} else {
		LocalStorageService.removeAllToken();
		return <Navigate to={{ pathname: routeURLs }} replace={true} />;
	}
}

export default AuthRoute;
