import React from 'react';
import PropTypes from 'prop-types';

const WavyDividerComp = ({ text = "" }) => {
	return (
		<div className="tw-w-full tw-flex tw-items-center tw-justify-center tw-gap-2.5 tw-my-5 tw-mx-0">
			{/* Left wave */}
			<div style={styles.wave} />

			{/* Centered Text */}
			<span className='tw-text-color-text-subtle tw-typography-caption1'>{text}</span>

			{/* Right wave */}
			<div style={styles.wave} />
		</div>
	);
};

WavyDividerComp.propTypes = {
	text: PropTypes.string,
};

export const WavyDivider = React.memo(WavyDividerComp);



// Styles
const styles = {
	wave: {
		flex: 1, // Makes the waves expand to fill available space
		height: "10px",
		backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='10' viewBox='0 0 40 10'><path d='M0 5 Q 10 0, 20 5 T 40 5' fill='none' stroke='%23363E4F' stroke-width='2'/></svg>")`,
		backgroundRepeat: "repeat-x", // Repeat horizontally
		backgroundSize: "15px 10px", // Size of one wave (adjust as needed)
	}
};