import * as React from 'react';

export const AirConditioner = React.memo(
	({ size = 16, color = '#6B7280', className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...rest}
			>
				<path
					d="M8 1C8.24162 1 8.4375 1.19588 8.4375 1.4375V4.10003L10.0969 2.44064C10.2677 2.26979 10.5448 2.26979 10.7156 2.44064C10.8865 2.6115 10.8865 2.8885 10.7156 3.05936L8.4375 5.33747V7.5625H10.6625L12.9406 5.28439C13.1115 5.11354 13.3885 5.11354 13.5594 5.28439C13.7302 5.45525 13.7302 5.73225 13.5594 5.90311L11.9 7.5625H14.5625C14.8041 7.5625 15 7.75838 15 8C15 8.24162 14.8041 8.4375 14.5625 8.4375H11.9L13.5594 10.0969C13.7302 10.2677 13.7302 10.5448 13.5594 10.7156C13.3885 10.8865 13.1115 10.8865 12.9406 10.7156L10.6625 8.4375H8.4375V10.6625L10.7156 12.9406C10.8865 13.1115 10.8865 13.3885 10.7156 13.5594C10.5448 13.7302 10.2677 13.7302 10.0969 13.5594L8.4375 11.9V14.5625C8.4375 14.8041 8.24162 15 8 15C7.75838 15 7.5625 14.8041 7.5625 14.5625V11.9L5.90311 13.5594C5.73225 13.7302 5.45525 13.7302 5.28439 13.5594C5.11354 13.3885 5.11354 13.1115 5.28439 12.9406L7.5625 10.6625V8.4375H5.33747L3.05936 10.7156C2.8885 10.8865 2.6115 10.8865 2.44064 10.7156C2.26979 10.5448 2.26979 10.2677 2.44064 10.0969L4.10003 8.4375H1.4375C1.19588 8.4375 1 8.24162 1 8C1 7.75838 1.19588 7.5625 1.4375 7.5625H4.10003L2.44064 5.90311C2.26979 5.73225 2.26979 5.45525 2.44064 5.28439C2.6115 5.11354 2.8885 5.11354 3.05936 5.28439L5.33747 7.5625H7.5625V5.33747L5.28439 3.05936C5.11354 2.8885 5.11354 2.6115 5.28439 2.44064C5.45525 2.26979 5.73225 2.26979 5.90311 2.44064L7.5625 4.10003V1.4375C7.5625 1.19588 7.75838 1 8 1Z"
					fill={color}
				/>
			</svg>
		);
	}
);

AirConditioner.displayName = 'AirConditioner';
